import { BoltIcon, ChatBubbleBottomCenterTextIcon, GlobeAmericasIcon, RocketLaunchIcon } from '@heroicons/react/24/outline';
import Modal from './Modal';
import { useState } from 'react';

export default function JustForFun() {
  const [openModal, setOpenModal] = useState(false);
  const modalHandler = (open) => setOpenModal(open);

  const sections = [
    { header: "Cleveland, Ohio", icon: GlobeAmericasIcon },
    { header: "she/her", icon: ChatBubbleBottomCenterTextIcon },
    { header: "TypeScript is my love language", icon: RocketLaunchIcon },
    {
      header: (
        <button onClick={() => modalHandler(true)} className='underline hover:underline-offset-4'>
          Crafting Enthusiast
        </button>
      ),
      icon: BoltIcon,
    },
  ];

  return (
    <div className='bg-gold py-20'>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-y-6 gap-x-12'>
          <img
            src='https://res.cloudinary.com/ddiz3qvnk/image/upload/f_auto/q_auto/v1701815200/selgz/selgz_pu4gvp.png'
            alt='Selga Muiznieks'
            className='justify-self-center order-2 md:order-1 w-1/2 md:w-auto'
          />
          <div className='text-black order-1 md:order-2'>
            <h2 className='text-3xl font-bold tracking-tight accent sm:text-4xl mb-6 md:my-10'>
              a little bit about me...
            </h2>
            {sections.map((section) => (
              <div key={section.header} className='flex flex-row items-start mt-6'>
                <section.icon className='h-8 w-8' aria-hidden='true' />
                <p className='ml-3 font-semibold text-2xl another-accent'>
                  {section.header}
                </p>
              </div>
            ))}
            <Modal open={openModal} onModalClick={modalHandler} />
          </div>
        </div>
      </div>
    </div>
  );
}
import Education from "./Education";
import Experience from "./Experience";
import Footer from "./Footer";
import Intro from "./Intro";
import JustForFun from "./JustForFun";

export default function About() {
  return (
    <div className='bg-violet min-h-screen text-white'>
      <Intro />
      <JustForFun />
      <div className='bg-white pb-24'>
        <Experience />
        <Education />
      </div>
      <Footer />
    </div>
  );
}

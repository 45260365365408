import Section from "./Section";

const experience = [
  {
    header: "CoverMyMeds",
    subheader: "Software Developer",
    location: "Remote",
    date: "May 2021 - Present",
    summary: [
      "Full-stack Agile development using React, Remix, Node, Rails, PostgreSQL & beyond",
      "Led legacy application rewrite using Remix and React",
      "Support, monitor (using Sentry and DataDog) and maintain applications in production",
      "Voted PR of the Month by peers across technology department in March 2023",
      "Mentor intern by pairing, providing feedback for capstone project, and supporting through interview process (psst... she got the job!)",
    ],
  },
  {
    header: "Emeritus / MIT xPRO",
    subheader: "Learning Facilitator",
    location: "Remote",
    date: "September 2021 - August 2022",
    summary: [
      "React, Node, Express, MongoDB",
      "Weekly office hours to support students as they build, test, and deploy a web app",
      "Respond to questions asynchronously about course content and assignments",
      "Collaborate with the Emeritus team to solve course challenges and suggest improvements",
    ],
  },
  {
    header: "Hyland",
    subheader: "Software Developer",
    location: "Cleveland, Ohio",
    date: "July 2018 - April 2021",
    summary: [
      "Angular, C#, MSSQL, Git, Jenkins, Docker",
      "Learned new languages and technologies independently",
      "Collaborated in modernization sketch sessions and API design discussions",
      "Led team initiative to improve code quality through CI",
      "Encouraged and empowered girls and young women to study and pursue a career in STEM by volunteering at tech-outreach programs that focus on girls in tech",
    ],
  },
];

export default function Experience() {
  return <Section header='Experience' blocks={experience} columns={true} />;
}

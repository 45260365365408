export default function Block({header, subheader, location, date, summary}) {
  return (
    <article className='flex flex-col items-start justify-between'>
      <div className='group relative'>
        <h3 className='text-lg font-semibold leading-6 text-gray-900'>
          {header}
        </h3>
        <p className='text-sm leading-6 text-gray-600 font-bold'>
          {subheader}
        </p>
        <div className='text-xs text-gray-500'>
          <p>{location}</p>
          <p>{date}</p>
        </div>
        {summary && (
          <ul className='mt-5 text-sm leading-6 text-gray-600 list-disc list-inside sm:list-outside'>
            {summary.map((x) => (
              <li key={x}>{x}</li>
            ))}
          </ul>
        )}
      </div>
    </article>
  );
}

import { DocumentTextIcon, EnvelopeIcon, UsersIcon } from "@heroicons/react/24/outline";

const sections =[
  { header: 'Say hello', icon: EnvelopeIcon, href: 'mailto:hello@selgz.com' },
  { 
    header: 'Let\'s connect',
    icon: UsersIcon,
    href: 'https://www.linkedin.com/in/selgamuiznieks/',
  },
  // { header: 'Save a copy of my resume', icon: DocumentTextIcon, href: '/SelgaMuiznieks.pdf' },
]

export default function Footer() {
  return (
    <div className='relative isolate overflow-hidden py-16 sm:py-24 lg:py-32'>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <div className='mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-4'>
          <div className='max-w-xl lg:max-w-lg'>
            <h2 className='text-3xl font-bold tracking-tight text-white sm:text-4xl accent'>
              Stay in touch
            </h2>
          </div>
          <div className='grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-3 lg:pt-2 lg:col-span-3'>
            {sections.map((section) => (
              <a
                key={section.header}
                href={section.href}
                target='blank'
                className='group'
              >
                <div className='flex flex-row items-start'>
                  <div className='rounded-md bg-white/5 p-2 ring-1 ring-white/10 group-hover:ring-plum'>
                    <section.icon
                      className='h-6 w-6 text-white'
                      aria-hidden='true'
                    />
                  </div>
                  <p className='mt-1 ml-4 font-semibold text-2xl text-white another-accent group-hover:underline decoration-plum underline-offset-8'>
                    {section.header}
                  </p>
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function Modal({open, onModalClick}) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={onModalClick}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg'>
                <div className='bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4'>
                  <div className='sm:flex sm:items-start'>
                    <div className='mt-3 text-center sm:mt-0 sm:text-left'>
                      <Dialog.Title
                        as='h3'
                        className='another-accent text-2xl font-semibold leading-6'
                      >
                        I'll try any craft once
                      </Dialog.Title>
                      <div className='mt-2'>
                        <img
                          className='rounded-lg'
                          src='https://res.cloudinary.com/ddiz3qvnk/image/upload/f_auto/q_auto/v1701815200/selgz/qr_ra3uij.jpg'
                          alt='Embroidered QR code made by Selga'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='bg-white px-4 pb-3 sm:flex sm:flex-row-reverse sm:px-6'>
                  <button
                    type='button'
                    className='inline-flex w-full justify-center rounded-md bg-violet px-3 py-2 another-accent text-xl font-semibold text-white shadow-sm sm:ml-3 sm:w-auto'
                    onClick={() => onModalClick(false)}
                  >
                    Wow!
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default function Intro() {
  return (
    <div className='relative isolate px-6 pt-14 lg:px-8'>
      <div className='mx-auto max-w-2xl py-32'>
        <div className='text-center'>
          <h1 className='text-6xl sm:text-8xl tracking-tight accent'>Selga Muiznieks</h1>
          <p className='mt-6 text-lg leading-8'>
            Cleveland-based full-stack developer
          </p>
          <div className='mt-10 flex items-center justify-center gap-x-6'>
            <a
              href='mailto:hello@selgz.com'
              target='blank'
              className='rounded-md bg-plum px-3.5 py-2.5 text-xl font-semibold text-violet hover:text-white shadow-sm hover:bg-violet hover:outline outline-plum another-accent'
            >
              Say hello
            </a>
            <a
              href='https://www.linkedin.com/in/selgamuiznieks/'
              className='rounded-md bg-plum px-3.5 py-2.5 text-xl font-semibold text-violet hover:text-white shadow-sm hover:bg-violet hover:outline outline-plum another-accent'
              target='blank'
            >
              Let's connect
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

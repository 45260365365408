import { Navigate, Routes, Route } from "react-router-dom";
import About from "./components/About";

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<About />} />
        <Route path='*' element={<Navigate to='/' replace />} />
      </Routes>
    </>
  );
}

export default App;

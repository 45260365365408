import Section from "./Section";

const education = [
  {
    header: "Case Western Reserve University",
    subheader: "CWRU Coding Bootcamp, Full-Stack JavaScript Web Development",
    location: "Cleveland, Ohio",
    date: "October 2017 - April 2018",
  },
  {
    header: "McGill University",
    subheader:
      "Bachelor of Commerce, Major in Accounting, Minor in Environment",
    location: "Montreal, Quebec, Canada",
    date: "2009 - 2013",
  },
];

export default function EducationAgain() {
  return <Section header='Education' blocks={education} columns={false} />;
}

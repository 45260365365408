import Block from "./Block";

export default function Section({header, blocks, columns}) {
  // columns = true if you want 3 columns, otherwise 2
  const columnStyle = columns ? 'lg:grid-cols-3' : 'lg:grid-cols-2';

  return (
    <div className='bg-white pt-20'>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <div className='mx-auto max-w-2xl lg:mx-0'>
          <h2 className='text-3xl font-bold tracking-tight text-violet accent sm:text-4xl'>
            {header}
          </h2>
        </div>
        <div className={`mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 lg:mx-0 lg:max-w-none ${columnStyle}`}>
          {blocks.map((block) => (
            <Block key={block.header} {...block} />
          ))}
        </div>
      </div>
    </div>
  );
}
